import { createContext, useState, useEffect } from 'react'

export const CartContext = createContext()

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : []);
  const [checkoutUrl, setCheckoutUrl] = useState('');
  const [checkoutId, setCheckoutId] = useState('');

  useEffect(() => {
    // Function to fetch the initial checkoutId from the backend
    const fetchCheckoutUrlAndId = async () => {
      try {
        console.log("about to make call");
        const response = await fetch(`${process.env.REACT_APP_DISORDER_API_URL}/api/shopify/createCheckout`);
        const data = await response.json();
        console.log("In Cart Context Response: ", data);
        setCheckoutUrl(data.checkoutUrl);
        setCheckoutId(data.checkoutId);
      } catch (error) {
        console.error('Error fetching the checkout ID:', error);
      }
    };

    fetchCheckoutUrlAndId();
  }, []); // Empty dependency array ensures this runs only once when the component mounts


  const addToCart = async (variant) => {
    console.log("front end add to cart");
    const isItemInCart = cartItems.find((cartItem) => cartItem.id === variant.id);

    console.log(isItemInCart);
    if (isItemInCart) {
      setCartItems(
        cartItems.map((cartItem) =>
          cartItem.id === variant.id
            ? { ...cartItem, cartQuantity: cartItem.cartQuantity + 1 }
            : cartItem
        )
      );

      // api call to add variant to cart
      const response = await fetch(`${process.env.REACT_APP_DISORDER_API_URL}/api/shopify/addItemToCheckout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ item: variant, checkoutId }),
      });
    } else {
      // api call to add variant to cart
      const response = await fetch(`${process.env.REACT_APP_DISORDER_API_URL}/api/shopify/addItemToCheckout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ item: variant, checkoutId }),
      });

      setCartItems([...cartItems, { ...variant, cartQuantity: 1 }]);
    }
  };

  const removeFromCart = async (variant) => {
    const isVariantInCart = cartItems.find((cartVariant) => cartVariant.id === variant.id);

    if (isVariantInCart.cartQuantity === 1) {
      // api to remove variant from checkout
      const response = await fetch(`${process.env.REACT_APP_DISORDER_API_URL}/api/shopify/updateLineItemQuantity`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ variant, checkoutId, quantity: 1 }),
      });

      setCartItems(cartItems.filter((cartVariant) => cartVariant.id !== variant.id));
    } else {
      // api to remove variant from checkout
      const response = await fetch(`${process.env.REACT_APP_DISORDER_API_URL}/api/shopify/updateLineItemQuantity`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ variant, checkoutId, quantity: 1 }),
      });

      setCartItems(
        cartItems.map((cartVariant) =>
          cartVariant.id === variant.id
            ? { ...cartVariant, cartQuantity: cartVariant.cartQuantity - 1 }
            : cartVariant
        )
      );
    }
  };

  const clearCart = () => {
    setCartItems([]);
  };

  const getCartTotal = () => {
    return cartItems.reduce((total, variant) => total + variant.price.amount * variant.cartQuantity, 0);
  };

  const getCartCount = () => {
    return cartItems.length;
  };

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  useEffect(() => {
    const cartItems = localStorage.getItem("cartItems");
    if (cartItems) {
      setCartItems(JSON.parse(cartItems));
    }
  }, []);

  return (
    <CartContext.Provider
      value={{
        cartItems,
        checkoutId,
        checkoutUrl,
        addToCart,
        removeFromCart,
        clearCart,
        getCartTotal,
        getCartCount,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
