import { Container, Row, Col } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import { CartContext } from '../context/Cart';
import { useContext, useState } from 'react';

import MenuDrawer from './MenuDrawer';
import CartDrawer from './CartDrawer';

// styles
import '../styles/components/topMenu.css';

// assets
import menuIcon from '../assets/images/MenuIcon.png';
//import menuIconWhite from '../assets/images/MenuIconWhite.png';
//import DisorderTextLogo from '../assets/images/DisorderUnderMenuGraphicCropped.png'
//import DisorderTimeCapsuleImage from '../assets/images/DisorderTimeCapsuleImage.png'

function TopMenu() {
    const [isMenuDrawerOpen, setIsMenuDrawerOpen] = useState(false);
    const [isCartDrawerOpen, setIsCartDrawerOpen] = useState(false);
    const { cartItems } = useContext(CartContext);

    const navigate = useNavigate();  // Initialize useNavigate

    const toggleMenuDrawer = () => {
        setIsMenuDrawerOpen((prevState) => !prevState);
    };

    const toggleCartDrawer = () => {
        setIsCartDrawerOpen((prevState) => !prevState);
    };

    // Click handler for the "DISORDER" link
    const handleHomeClick = (e) => {
        e.preventDefault();  // Prevent default link behavior
        if (window.location.pathname === "/home") {
            // If already on the home page, force a reload
            window.location.reload();
        } else {
            // Otherwise, navigate to the home page
            navigate("/home");
        }
    };

    return (
        <nav className='bg-navbar'>
            <Container fluid className='menu-fonty top-menu-bar'>
                <Row style={{backgroundColor:"white"}} className='menu-icon-text top-menu-bar-row'>
                    <Col className='text-center m-auto'>
                        <div>
                            <MenuDrawer isOpen={isMenuDrawerOpen} toggleDrawer={toggleMenuDrawer}></MenuDrawer>
                            <div className='menu-icon-div' onClick={toggleMenuDrawer}>
                                <Image className='menu-icon' fluid src={menuIcon} />
                                MENU
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} sm={6} md={6} className="menu-home-div d-flex flex-column justify-content-center align-items-center text-center">
                        <div>
                            <a href="/home" className="menu-home-link" onClick={handleHomeClick}>
                                DISORDER
                            </a>
                        </div>
                    </Col>
                    <Col className="text-center m-auto">
                        <div>
                            <CartDrawer isOpen={isCartDrawerOpen} toggleDrawer={toggleCartDrawer}></CartDrawer>
                            <div className='cart-icon-div' onClick={toggleCartDrawer}>
                                CART ({cartItems.length})
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </nav>
    );
}

export default TopMenu;