import { Container, Row, Col, Image, Carousel, Card } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import SelectableVariants from '../components/SelectableVariants';

//import StockPhoto from '../assets/images/DisorderUnderMenuGraphic.png';
//import hoodie1 from '../assets/images/Products/hoodie_stone.png';
//import hoodie2 from '../assets/images/Products/hoodie_tar.png';

import { CartContext } from '../context/Cart';

// styling
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/pages/productPage.css'

function ProductPage() {
    useEffect(() => {
        const body = document.querySelector('#root');
    
        body.scrollIntoView({
            behavior: 'auto'
        }, 500)
    
    }, []);
    const { id } = useParams();
    const decodedId = decodeURIComponent(id);
    const location = useLocation();
    const { product } = location.state || {};

    const {cartItems, addToCart } = useContext(CartContext);

    if (!product) {
        return <div>No product data available.</div>;
    }

    return (
    <Container className='fonty'>
        <Row className="align-items-center">
            <Col xs={12} md={6} className="text-center">
                <Carousel interval={null} data-bs-theme="dark" indicators={true}>
                    {product.images.map((images, index) => (
                        <Carousel.Item key={index}>
                            <Image className='carousel-product-image' src={images.src} />
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Col>
            <Col xs={12} md={6}>
                    <Col>
                        {/* <h6 className=''>
                            {product.title} - ${parseFloat(product.variants[0].price.amount).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}
                        </h6> */}
                        <Card className='border-0'>
                            <Card.Body>
                                <Card.Title className='mb-4 fs-3'>{product.title}</Card.Title>
                                <Card.Subtitle className="mb-4 text-muted">${parseFloat(product.variants[0].price.amount).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</Card.Subtitle>
                                <Card.Text className='product-description-text'>
                                    <p style={{paddingTop:"10px"}} dangerouslySetInnerHTML={{ __html: product.descriptionHtml }} />
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* <Col>
                    {product.variants.map((variant, variantIdx) => (
                        <Image rounded src={variant.image?.src} style={{
                            width: "10%",
                            height: "10%",
                            objectFit: "cover", // or "contain" depending on your preference
                          }} />
                    ))}
                    </Col> */}
                    <Col className='selectable-variants'>
                        <SelectableVariants variants={product.variants}/>
                    </Col>
                {/* <SelectableVariants variants={product.variants}/>
                <p style={{paddingTop:"50px"}} dangerouslySetInnerHTML={{ __html: product.descriptionHtml }} /> */}
            </Col>
        </Row>
    </Container>
    );
}

export default ProductPage;