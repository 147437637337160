import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';

// should make sure to check that each entry is required

const ContactPage = () => {
    useEffect(() => {
        const body = document.querySelector('#root');
    
        body.scrollIntoView({
            behavior: 'auto'
        }, 500)
    
    }, []);

    // State to store form values
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        subject: '',
        message: ''
    });

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

// Handle form submission
const handleSubmit = async (e) => {
    e.preventDefault();

    // Optional: Add basic form validation (you can expand this logic)
    if (!formData.firstName || !formData.lastName || !formData.email || !formData.subject || !formData.message) {
        alert('All fields are required');
        return;
    }

    try {
        // Send a POST request to the backend with formData
        const response = await fetch(`${process.env.REACT_APP_DISORDER_API_URL}/api/mail/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        const result = await response.json();

        if (response.ok) {
            // Show success message
            alert('Message sent successfully!');
        } else {
            // Handle error
            alert(`Failed to send message: ${result.message}`);
        }

        // Clear the form fields after successful submission
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            subject: '',
            message: ''
        });

    } catch (error) {
        console.error('Error sending form data:', error);
        alert('There was a problem sending the email.');
    }
};

    return (
        <Container className='fonty' style={{paddingTop:"16vh", paddingBottom:"6vh"}}>
            <h2>Contact Us</h2>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="formFirstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your first name"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="formLastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your last name"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Form.Group controlId="formEmail" className="mt-3">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Enter your email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="formSubject" className="mt-3">
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter the subject"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="formMessage" className="mt-3">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Enter your message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Button variant="outline-dark" type="submit" className="mt-4">
                    Submit
                </Button>
            </Form>
        </Container>
    );
};

export default ContactPage;