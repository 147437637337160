import { useContext } from 'react';
import { CartContext } from '../context/Cart';
import { Offcanvas, Container, Row, Col, Stack, Button } from 'react-bootstrap';
import CartItem from './CartItem';
import '../styles/components/cartDrawerStyles.css';

const CartDrawer = ({ isOpen, toggleDrawer }) => {
    const { cartItems, checkoutUrl, getCartCount, getCartTotal } = useContext(CartContext);

    return (
        <Offcanvas className='ofc-width cart-drawer-font' show={isOpen} onHide={toggleDrawer} placement="end">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>CART ({getCartCount()})</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Stack>
                    {cartItems.map((inCartItem, inCartItemIdx) => (
                        <div className='m-2' key={inCartItemIdx}>
                            <CartItem variant={inCartItem} />
                        </div>
                    ))}
                </Stack>
                <Container className='cart-drawer-footer'>
                    <Row className='mb-2'>
                        <Col style={{ textAlign: 'right' }}>
                            SUBTOTAL: ${getCartTotal()}
                        </Col>
                    </Row>
                    <Row>
                        <Col className='d-grid gap-2'>
                            <Button variant='outline-dark' disabled={cartItems.length === 0} href={checkoutUrl}>
                                Checkout
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default CartDrawer;