import '../styles/pages/home.css';
import { Link } from "react-router-dom";


// images
import consumeImg from '../assets/images/ConsumeForLightMode.png';
import contactImg from '../assets/images/ContactForLightMode.png';
import infoImg from '../assets/images/InfoForLightMode.png';
import galleryImg from '../assets/images/GalleryForLightMode.png';

const HomePage = () => {
    return (
        <div style={{paddingTop:"2vh"}} className="fonty">
            <Link to="/products">
                <div className="image-container">
                    <img className="home-page-image" src={consumeImg} alt='SHOP'></img>
                    <div style={{bottom:"20%"}} className="image-text">SHOP</div>
                </div>
            </Link>
            <Link to="/gallery">
                <div className="image-container">
                    <img className="home-page-image" src={galleryImg} alt='GALLERY'></img>
                    <div style={{bottom:"1%"}} className="image-text">GALLERY</div>
                </div>
            </Link>
            <Link to="/contact">
                <div className="image-container">
                    <img className="home-page-image" src={contactImg} alt='CONTACT'></img>
                    <div style={{bottom:"15%"}} className="image-text">CONTACT</div>
                </div>
            </Link>
            <Link to="/about">
                <div className="image-container">
                    <img className="home-page-image" src={infoImg} alt='INFO'></img>
                    <div style={{bottom:"4%"}} className="image-text">INFO</div>
                </div>
            </Link>
        </div>



        // <Container>
        //     <Col className="image-container">
        //             <Link to="/products">
        //                 <Image src={consumeImg} alt="CONSUME" className="home-image"/>
        //                 <div className="overlay-text">SHOP</div>
        //             </Link>
        //     </Col>
        //     <Col>
        //         <div className="image-container text-center">
        //             <Link to="/contact">
        //                 <Image src={contactImg} alt="CONTACT" className="img-fluid w-25 h-25"/>
        //                 <div className="overlay-text">CONTACT</div>
        //             </Link>
        //         </div>
        //     </Col>
        //     <Col>
        //         <div className="image-container text-center">
        //             <Link to="/about">
        //                 <Image src={infoImg} alt="INFO" className="img-fluid w-25 h-25"/>
        //                 <div className="overlay-text">INFO</div>
        //             </Link>
        //         </div>
        //     </Col>
        //     <Col>
        //         <div className="image-container text-center">
        //             <Link to="/gallery">
        //                 <Image src={galleryImg} alt="GALLERY" className="img-fluid w-25 h-25"/>
        //                 <div className="overlay-text">GALLERY</div>
        //             </Link>
        //         </div>
        //     </Col>

        // </Container>
    );
};

export default HomePage;