import { Stack, Image, Spinner } from 'react-bootstrap';
import { useState } from 'react';

const galleryImagePaths = [
    '/images/Gallery/image00009.jpeg',
    '/images/Gallery/image00024.jpeg',
    '/images/Gallery/image00025.jpeg',
    '/images/Gallery/image00026.jpeg',
    '/images/Gallery/image00029.jpeg',
    '/images/Gallery/image00001.jpeg',
    '/images/Gallery/image00002.jpeg',
    '/images/Gallery/image00003.jpeg',
    '/images/Gallery/image00004.jpeg',
    '/images/Gallery/image00005.jpeg',
    '/images/Gallery/image00006.jpeg',
    '/images/Gallery/image00007.jpeg',
    '/images/Gallery/image00008.jpeg',
    '/images/Gallery/image00010.jpeg',
    '/images/Gallery/image00011.jpeg',
    '/images/Gallery/image00012.jpeg',
    '/images/Gallery/image00013.jpeg',
    '/images/Gallery/image00014.jpeg',
    '/images/Gallery/image00015.jpeg',
    '/images/Gallery/image00016.jpeg',
    '/images/Gallery/image00017.jpeg',
    '/images/Gallery/image00018.jpeg',
    '/images/Gallery/image00019.jpeg',
    '/images/Gallery/image00020.jpeg',
    '/images/Gallery/image00021.jpeg',
    '/images/Gallery/image00023.jpeg',
    '/images/Gallery/image00027.jpeg',
    '/images/Gallery/image00028.jpeg',
    '/images/Gallery/image00022.jpeg'
];

const GalleryPage = () => {
    // Create an array of states to track loading for each image
    const [loaded, setLoaded] = useState(Array(galleryImagePaths.length).fill(false));

    // Handle image load event
    const handleImageLoad = (index) => {
        setLoaded(prevState => {
            const newLoadedState = [...prevState];
            newLoadedState[index] = true; // Mark the image as loaded
            return newLoadedState;
        });
    };

    return (
        <Stack>
            {galleryImagePaths.map((galleryImagePath, imageIndex) => (
                <div key={imageIndex} style={{ position: 'relative' }}>
                    {!loaded[imageIndex] && (
                        <div style={{ 
                            position: 'absolute', 
                            top: '50%', 
                            left: '50%', 
                            transform: 'translate(-50%, -50%)' 
                        }}>
                            <Spinner animation="border" />
                        </div>
                    )}
                    <Image
                        fluid
                        src={galleryImagePath}
                        style={{ width: '100vw', objectFit: 'cover' }} 
                        onLoad={() => handleImageLoad(imageIndex)} // Call when the image loads
                    />
                </div>
            ))}
        </Stack>
    );
};

export default GalleryPage;