//import consumeImg from '../assets/images/ConsumeForLightMode.png';
import { Container, Col, Row } from 'react-bootstrap';
import { useEffect } from 'react';

const AboutPage = () => {
    useEffect(() => {
        const body = document.querySelector('#root');
    
        body.scrollIntoView({
            behavior: 'auto'
        }, 500)
    
    }, []);
    const textArray = [
        'Do what you love, fuck the rest. No info yet',
        'Do what you love, fuck the rest. No info yet',
        'Do what you love, fuck the rest. No info yet',
        'Do what you love, fuck the rest. No info yet',
        'Do what you love, fuck the rest. No info yet',
        'Do what you love, fuck the rest. No info yet',
        'Do what you love, fuck the rest. No info yet',
        'Do what you love, fuck the rest. No info yet',
        'Do what you love, fuck the rest. No info yet',
        'Do what you love, fuck the rest. No info yet',
        'Do what you love, fuck the rest. No info yet',
        'Do what you love, fuck the rest. No info yet',
    ];

    return (
        <Container>
            <Row>
                <Col className='fonty' style={{marginTop:'15vh', marginBottom:'10vh'}}>
                    {textArray.map((text, index) => (
                        <p key={index} style={{ textAlign: index % 2 === 0 ? 'left' : 'right' }}>
                            {text}
                        </p>
                    ))}
                </Col>
            </Row>
        </Container>
    );
};

export default AboutPage;