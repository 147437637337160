import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Image, Button, Form, Row, Col, Container, InputGroup, Alert } from 'react-bootstrap';
import StartupImage from '../assets/images/DisorderEntryGraphic.jpeg';
import '../styles/pages/startupPage.css';
import { useAuth } from '../context/Auth';

const StartupPage = () => {
    const [password, setPassword] = useState('');
    const [showAlert, setShowAlert] = useState(false);  // New state for showing the alert
    const { login } = useAuth();
    const navigate = useNavigate();

    const checkPassword = async (pwd) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_DISORDER_API_URL}/api/auth/attemptPasswordPageLogin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ password: pwd }),
            });

            if (!response.ok) {
                return false;
            }

            const data = await response.json();
            return data.success;
        } catch (error) {
            console.error('Error during login attempt:', error);
            return false;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const checkPWD = await checkPassword(password);

        if (checkPWD) {
            login();
            navigate('/home');  // Redirect to home page after successful login
        } else {
            setPassword('');
            setShowAlert(true);  // Show the alert if the password is wrong
        }
    };

    return (
        <Container fluid className="startup-container p-0">
            {/* Image Section */}
            <Image
                fluid 
                src={StartupImage} 
                style={{ width: '100vw', height: '100vh', objectFit: 'cover' }} 
                rounded 
            />
        
            <div className="password-overlay overlay-text">
                {/* Alert Section */}
                {showAlert && (
                    <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                        WRONG PASSWORD.
                    </Alert>
                )}

                <Form onSubmit={handleSubmit} className="password-form pwd-box">
                    <InputGroup>
                        <Form.Control
                            type="password"
                            placeholder="Enter Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="password-input"
                        />
                        <Button type="submit" className="password-submit" variant="light">
                            Submit
                        </Button>
                    </InputGroup>
                </Form>
            </div>
        </Container>
    );
};

export default StartupPage;