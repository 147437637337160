import React, { useEffect, useState } from 'react';
import { Card, Container, Col, Row, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import StockPhoto from '../assets/images/DisorderUnderMenuGraphic.png';
import '../styles/pages/productListPage.css';

const chunkArray = (array, size) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

function ProductListPage() {
  const { collection } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true); // New state to track loading
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        console.log("fetching");
        setLoading(true); // Start loading when fetching begins
        const response = await fetch(`${process.env.REACT_APP_DISORDER_API_URL}/api/shopify/collections?collection=${collection}`);
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log('Fetched products:', data);

        if (data.length > 0) {
          setProducts(data);
        } else {
          console.warn('No products found in response');
          setProducts([]);
        }
      } catch (error) {
        console.error('Error fetching products:', error);
        setProducts([]);
      } finally {
        setLoading(false); // End loading regardless of success or failure
      }
    };

    fetchProducts();
  }, [collection]);

  const handleProductClick = (product) => {
    const encodedId = encodeURIComponent(product.id);
    navigate(`/product/${encodedId}`, { state: { product }});
  };

  const chunkedProducts = chunkArray(products, 2);

  return (
    <Container className='product-list-page-container' fluid>
      {loading ? (
        <div className='fonty'>
          <Row style={{height:"100vh"}}>
            <Col className='text-center m-auto'>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Col>
          </Row>
        </div>
      ) : products.length === 0 ? (
        <div className="fonty">
          <Row style={{height:"100vh"}}>
            <Col className='text-center m-auto'>
              COMING SOON
            </Col>
          </Row>
        </div>
      ) : (
        chunkedProducts.map((productRow, rowIndex) => (
          <Row key={rowIndex} className="mb-4">
            {productRow.map((product, colIndex) => {
              const productImage = product.images?.[0]?.src || StockPhoto;
              return (
                <Col key={colIndex} xs={6} md={6} className="d-flex">
                  <Card className="product-card border-0" onClick={() => handleProductClick(product)}>
                    <Card.Img variant="top" src={productImage} className="card-img-top" />
                    <Card.Body className='fonty'>
                      <Card.Title className='card-title-text'>{product.title}</Card.Title>
                      <Card.Text className='card-text-text'>
                        Price: ${product.variants[0].price.amount}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        ))
      )}
    </Container>
  );
}

export default ProductListPage;